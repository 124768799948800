import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AuthenticationService } from '../services/security/authentication.service';
import { SweetAltert2Service } from '../services/sweetalert2.service';
import { User } from '../models/user-model';
@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent {
    oUser: User;
    constructor(
        public layoutService: LayoutService,
        private oSweetAltert2Service:SweetAltert2Service,
        private oAuthenticationService: AuthenticationService) {
            this.oUser = oAuthenticationService.getUser();
        }

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    fnLogout(){
        this.visible=false;
        this.oSweetAltert2Service.confirm('Salir','Desea cerrar el sistema').then((result)=> {
            if (result.isConfirmed) {
                this.oAuthenticationService.logout();    
            }
        })
        
    }
}