import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AuthGuard } from './guards/auth.guard';
import { PagesModule } from './components/pages/pages.module';
import { NoAuthGuard } from './guards/no-auth.guard';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};

const routes: Routes = [
    { path: 'auth', canActivate: [NoAuthGuard], data: { breadcrumb: 'Auth' }, loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule) },
    { path: 'notfound', loadChildren: () => import('./components/notfound/notfound.module').then(m => m.NotfoundModule) },
    {
        path: '', component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'pages',
                canActivate: [AuthGuard],
                data: { breadcrumb: 'Pages' },
                loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule)
            },
            { path: '**', redirectTo: '/pages/application' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
