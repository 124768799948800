import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs'; 
import { GlobalVariableService } from './helpers/global.service';
import { AuthenticationService } from './services/security/authentication.service';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor( private oGlobalVariableService: GlobalVariableService, private oAuthenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Obtener el token de autenticación desde el almacenamiento local o las cookies
    let token = this.oGlobalVariableService.getTokenJWT();
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(request).pipe(      
      catchError((error) => {
        if (error.status === 401 || !this.oAuthenticationService.isAuthenticated()) {
          // Redirige a la página de login si el token ha expirado
          console.log('estas aqui como asi');
          this.oAuthenticationService.logout();
        }
        console.log('estas aqui');
        return throwError(error);
      })
    );
  }
}