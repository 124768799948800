import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../../helpers/storage.service';
import { User } from 'src/app/models/user-model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    jwtHelper: JwtHelperService = new JwtHelperService();
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private oStorageService: StorageService,
        private activatedRoute: ActivatedRoute,
        private router: Router) {
        
        this.userSubject = new BehaviorSubject<User>(JSON.parse(this.oStorageService.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    logout() {
        localStorage.clear();
        this.oStorageService.removeItem('user');
        //this.userSubject.next(null);
        this.oStorageService.removeAll();
        this.clearDataInformationPublicLocal();        
        this.router.navigate(['/auth/login']);
    }
    logoutSinRedirectToLogin() {
        localStorage.clear();
        this.oStorageService.removeItem('user');
        //this.userSubject.next(null);
        this.oStorageService.removeAll();
        this.clearDataInformationPublicLocal();        
    }

    saveDataInformationPublicLocal(input: User) {
        this.oStorageService.setItem('DataAuthentication', JSON.stringify(input));
    }

    getDataInformationPublicLocal(): User {
        return JSON.parse(this.oStorageService.getItem('DataAuthentication'));
    }

    clearDataInformationPublicLocal() {
        this.oStorageService.removeItem('DataAuthentication');
    }

    isAuthenticated(): boolean {        
        //Verificar si la URL comienza con '/auth/login'  
        if (this.activatedRoute.snapshot.url.toString().startsWith('/auth/login'))
            return true;
        //Obtener el token del almacenamiento
        let token = this.oStorageService.getItem('JWT');
        if (token === null)
            return false;
        // Verificar si el token existe y no ha expirado
        return this.jwtHelper.isTokenExpired(token) === false && this.isAuthenticatedInValue(); 
    }

    isAuthenticatedInValue(): boolean {
        // Implementa lógica adicional si es necesario para verificar otras condiciones de autenticación
        // por ejemplo, verifica roles, permisos, etc.
        const token = this.oStorageService.getItem('JWT');
        if (token == null) {
            return false;
        }
      
        const decodedToken = this.jwtHelper.decodeToken(token);
        if (decodedToken == null) {
            return false;
        }
        //console.log('decodetoken--',decodedToken);
        return true
        //return atob(decodedToken.Autenticado) === '1';
    }

    getUser(): User {
        let dataAuthentication = JSON.parse(this.oStorageService.getItem('DataAuthentication'));
        //if(!dataAuthentication)return null;
        return dataAuthentication;

    }
}