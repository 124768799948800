export const environment = {
    services: {
        Identity      : "https://api-sso-security-dev.ceplan.gob.pe/api/"
    },
    production: true,
    development: false,

    encryptation: {
        enabled: true,
        key: '484060556f8744ab799c69961bc5a6c190f22097',
        excluded: []
    },
    timeoutminuser:15,
};
